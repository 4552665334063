/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC343P
 * 화면 설명  : 고충사항 등록
 */
<template>
    <!-- Popup003 -->
    <mo-bottom-sheet ref="popup" close-btn class="ns-bottom-sheet closebtn title-noline" @closed="$emit('closed')">
      <template v-slot:title>
        고충사항 등록
        <!-- <div class="ns-btn-close" @click="close2" name="닫기"></div> -->
        <div class="mt10 sub-text">
          <span>{{lv_UserInfo.userNm}}</span><em v-if="!$bizUtil.isEmpty(lv_DiffiRegInfo.telno)"></em>
          <span>{{$commonUtil.fullPhoneDashFormat(lv_DiffiRegInfo.telno)}}</span>
        </div>
      </template>
       
      <div class="content-area">
        <mo-text-field  class="form-input-name full" v-model="diffiTitle" underline placeholder="제목을 입력해 주세요." />
        <mo-text-area class="full mt20" rows="8" v-model="diffiText" placeholder="내용을 입력해 주세요." />
      </div>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" :disabled="!diffiTitle || !diffiText" class="ns-btn-round blue" @click="fn_ValidateBeforeSubmit">등록</mo-button>
        </div>
      </ur-box-container>
    </mo-bottom-sheet>
    <!-- Popup003 end -->

</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC343P',
  screenId: 'MSPBC343P',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SelDiffiRegInfo()
  },
  mounted() {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  props: {
    opened: {
      type: Boolean,
      default: () => false
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_UserInfo: {
        userNm: this.getStore('userInfo').getters.getUserInfo.userNm
      },
      lv_DiffiRegInfo: {
        telno: '',
        emailAddr: ''
      },
      diffiTitle: '',
      diffiText: '',
    };
  },
  watch: {
    opened(val) {
      val && this.$refs.popup.open()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
      * Function명 : fn_SelDiffiRegInfo
      * 설명       : 고충사항 작성자 정보 조회
    ******************************************************************************/
    fn_SelDiffiRegInfo () {
      const trnstId = 'txTSSBC07S2'
      const auth = 'S'
      var lv_Vm = this

      var pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
      }

      this.post(lv_Vm, pParams, trnstId, auth, {}, false)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            lv_Vm.lv_DiffiRegInfo = response.body
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_InsDiffiTrtInfo
      * 설명       : 고충사항 등록
    ******************************************************************************/
    fn_InsDiffiTrtInfo () {
      const trnstId = 'txTSSBC07I1'
      const auth = 'I'
      var lv_Vm = this

      var pParams = {
        hofOrgNo: this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo,
        dofOrgNo: this.getStore('userInfo').getters.getUserInfo.onpstDofNo,
        fofOrgNo: this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo,
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        cnsltNm: this.getStore('userInfo').getters.getUserInfo.userNm,
        telno: lv_Vm.lv_DiffiRegInfo.telno,
        emailAddr: lv_Vm.lv_DiffiRegInfo.emailAddr,
        bltnTitl: lv_Vm.diffiTitle,
        bltnCntnt: lv_Vm.diffiText
      }

      this.getStore('progress').dispatch('FULL')
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.msgComm.msgCd === 'ECOU002') {
            lv_Vm.$refs.popup.close()
            lv_Vm.$addSnackbar('등록되었습니다')
            lv_Vm.fn_ClearData()
            lv_Vm.$emit('registered')
          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '등록시 오류가 발생하였습니다.')
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /*********************************************************
    * Function명: fn_ValidateBeforeSubmit
    * 설명: 고충 사항 등록 전 Validation 함수
    *********************************************************/
    fn_ValidateBeforeSubmit () {
      var lv_Vm = this
      if (this.diffiTitle.length < 1) {
        lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
        lv_Vm.getStore('confirm').dispatch('ADD', '제목을 입력해주세요.')
      } else if (this.diffiText.length < 1) {
        lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
        lv_Vm.getStore('confirm').dispatch('ADD', '내용을 입력해주세요.')
      } else if (this.diffiTitle.length > 50) {
        lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
        lv_Vm.getStore('confirm').dispatch('ADD', '제목은 50자를 초과할 수 없습니다.')
      } else if (this.diffiText.length > 1300) {
        lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
        lv_Vm.getStore('confirm').dispatch('ADD', '내용은 1300자를 초과할 수 없습니다.')
      } else {
        lv_Vm.fn_InsDiffiTrtInfo()
      }
    },

    /*********************************************************
    * Function명: fn_ClearData
    * 설명: 입력항목 초기화
    *********************************************************/
    fn_ClearData () {
      this.diffiTitle = ''
      this.diffiText = ''
    }
  }
};
</script>
<style scoped>
</style>