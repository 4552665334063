/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC341D
 * 화면 설명  : 고충처리방 고충사항 FAQ
 */
<template>
  <ur-box-container direction="column" alignV="start">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
      <ur-box-container v-if="bCDiffiTrtFaqListSVO.length" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
        <mo-list :list-data="bCDiffiTrtFaqListSVO">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents" @click="fn_OpenDetail(item)">
                <div class="list-item__contents__title">
                  <span class="name">{{item.bltnTitl}}</span>
                </div>
                <div class="list-item__contents__info mt10">
                  <div class="info_box">
                    <span>{{item.updtrNm}}</span><em v-if="item.updtrNm" class="mr10">|</em>
                    <span>{{$bizUtil.dateDashFormat(item.mdfcYmd)}}</span>
                  </div>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- no-data --> 
      <ur-box-container v-else-if="!isLoading" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
        <mo-list-item ref="expItem">
          <div class="list-item__contents">
            <div class="list-item__contents__info mt100">
              <span class="ns-ftcr-gray"><p class="mt20">등록된 게시물이 없어요.</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </ur-box-container>

    <!-- Popup001 -->
    <mo-bottom-sheet ref="popup" close-btn class="ns-bottom-sheet closebtn title-line ">
      <template v-slot:title>
        {{selectItem.bltnTitl}}
        <!-- <div class="ns-btn-close" @click="close" name="닫기"></div> -->
        <div class="mt10 sub-text">
          <span>{{selectItem.updtrNm}}</span><em v-if="!$bizUtil.isEmpty(selectItem.updtrNm)">|</em>
          <span>{{$bizUtil.dateDashFormat(selectItem.mdfcYmd)}}</span>
        </div>
      </template>
       
      <div class="content-area crTy-bk7 maxh-300" v-html="$cmUtil.convertCrrgRtrnStr(selectItem.bltnCntnt || '').trim()">
      </div>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.popup.close()">확인</mo-button>
        </div>
      </ur-box-container>
    </mo-bottom-sheet>
    <!-- Popup001 end -->

  </ur-box-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC341D',
  screenId: 'MSPBC341D',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_GetServiceS()
  },
  mounted() {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 고충처리방리스트 조회용 Service IF VO
      bCDiffiTrtFaqListSVO: [],
      stndKeyVal: 'start',
      // 더보기 key
      stndKeyList: [],
      // 현재 선택된 글
      selectItem: {},
      isLoading: true,
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_GetServiceS
    * 설명       : 고충처리방 리스트 조회
    *
    ******************************************************************************/
    fn_GetServiceS () {
      if (this.stndKeyVal === 'end') return
      const trnstId = 'txTSSBC07S1'
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')

      let pParams = {}

      this.isLoading = true
      this.getStore('progress').dispatch('SKEL_LIST')
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기

          if (response.body !== null && response.body !== '') {
            lv_Vm.stndKeyList = response.trnstComm.stndKeyList

            let responseList = response.body.iCQnaCorpSalesDtlVO

            if (responseList != null) {
              // lv_Vm.searchRes = ''

              for (let i = 0; i < responseList.length; i++) {
                responseList[i].showExpand = false
              }

              if (lv_Vm.stndKeyVal !== 'start') {
                for (var i = 0; i < responseList.length; i++) {
                  lv_Vm.bCDiffiTrtFaqListSVO.push(responseList[i])
                }
              } else {
                lv_Vm.bCDiffiTrtFaqListSVO = responseList
              }
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList.length === 0 || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
                lv_Vm.stndKeyVal = 'end'
              } else {
                lv_Vm.stndKeyVal = 'more'
              }
            }
          } else {
            // lv_Vm.searchRes = '1'
            confirm.dispatch('SETCOLOR', 'purple')
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
        .finally(() => {
          lv_Vm.isLoading = false
          lv_Vm.$emit('loaded', lv_Vm.bCDiffiTrtFaqListSVO.length || 0)
        })
    },
    /******************************************************************************
    * Function명 : fn_OpenDetail
    * 설명       : 고충처리방 상세 조회
    *
    ******************************************************************************/
    fn_OpenDetail(item) {
      this.selectItem = item
      this.$refs.popup.open()
    },
  }
};
</script>
<style scoped>
</style>